.mapboxgl-popup {
  z-index: 2;
}

.anim-bounce {
  animation: bounce 1.2s ease infinite;
  cursor: progress;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-4px);
  }
  60% {
    transform: translateY(-2px);
  }
}
